// Определяем базовый URL API в зависимости от окружения
const BASE_URL = process.env.REACT_APP_API_URL || 'https://qa-learning.ru';

// Функция регистрации
export const register = async (requestData) => {
    try {
        const response = await fetch(`${BASE_URL}/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-application-name': 'web',
            },
            body: JSON.stringify(requestData),
        });

        const data = await response.json();
        if (response.ok) {
            return data; // Возвращаем успешный результат
        } else {
            throw new Error(data.error || 'Ошибка регистрации');
        }
    } catch (error) {
        throw new Error('Ошибка подключения к серверу');
    }
};
