import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Grid, Container } from '@mui/material';

function QuestionsManager() {
    const [questions, setQuestions] = useState([]);
    const [newQuestion, setNewQuestion] = useState({ lessonNumber: '', questionText: '', answerText: '' });
    const [editingId, setEditingId] = useState(null);
    const [editedQuestion, setEditedQuestion] = useState({ lessonNumber: '', questionText: '', answerText: '' });

    // Загружаем вопросы с API при загрузке компонента
    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        try {
            const response = await fetch('http://localhost:8080/questions', {
                headers: { 'x-application-name': 'web' },
            });
            const data = await response.json();
            setQuestions(data);
        } catch (error) {
            console.error('Ошибка при получении вопросов:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await fetch(`http://localhost:8080/questions/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'x-application-name': 'web',
                    'Content-Type': 'application/json',
                },
            });
            setQuestions(questions.filter((question) => question.id !== id));
        } catch (error) {
            console.error('Ошибка при удалении вопроса:', error);
        }
    };

    const handleEdit = (id, lessonNumber, questionText, answerText) => {
        setEditingId(id);
        setEditedQuestion({ lessonNumber, questionText, answerText });
    };

    const handleSave = async (id) => {
        try {
            await fetch(`http://localhost:8080/questions/update/${id}`, {
                method: 'PUT',
                headers: {
                    'x-application-name': 'web',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedQuestion),
            });
            setQuestions(
                questions.map((question) =>
                    question.id === id ? { ...question, ...editedQuestion } : question
                )
            );
            setEditingId(null);
        } catch (error) {
            console.error('Ошибка при обновлении вопроса:', error);
        }
    };

    const handleAddQuestion = async () => {
        try {
            const response = await fetch('http://localhost:8080/questions/add', {
                method: 'POST',
                headers: {
                    'x-application-name': 'web',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newQuestion),
            });
            const addedQuestion = await response.json();
            setQuestions([...questions, addedQuestion]);
            setNewQuestion({ lessonNumber: '', questionText: '', answerText: '' });
        } catch (error) {
            console.error('Ошибка при добавлении вопроса:', error);
        }
    };

    return (
        <Container disableGutters maxWidth={false}>
            <Box sx={{ padding: '20px', width: '100%' }}>
                <Typography variant="h4" gutterBottom>Управление вопросами</Typography>

                {questions.map((question, index) => (
                    <Grid container key={question.id} sx={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                        <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="h6">{index + 1}</Typography>
                        </Grid>
                        <Grid item xs={11}>
                            {editingId === question.id ? (
                                <>
                                    <TextField
                                        label="Номер урока"
                                        value={editedQuestion.lessonNumber}
                                        onChange={(e) => setEditedQuestion({ ...editedQuestion, lessonNumber: e.target.value })}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        label="Вопрос"
                                        value={editedQuestion.questionText}
                                        onChange={(e) => setEditedQuestion({ ...editedQuestion, questionText: e.target.value })}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        label="Ответ"
                                        value={editedQuestion.answerText}
                                        onChange={(e) => setEditedQuestion({ ...editedQuestion, answerText: e.target.value })}
                                        fullWidth
                                        margin="normal"
                                        multiline // Позволяет вводить несколько строк
                                        rows={4}  // Устанавливает количество строк по умолчанию
                                        maxRows={8}  // Максимальное количество строк
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleSave(question.id)}
                                        sx={{ marginRight: '10px' }}
                                    >
                                        Сохранить
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => setEditingId(null)}
                                    >
                                        Отмена
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Typography variant="h6">Урок {question.lessonNumber}</Typography>
                                    <Typography variant="body1"><strong>Вопрос:</strong> {question.questionText}</Typography>
                                    <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}><strong>Ответ:</strong> {question.answerText}</Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleEdit(question.id, question.lessonNumber, question.questionText, question.answerText)}
                                        sx={{ marginRight: '10px' }}
                                    >
                                        Редактировать
                                    </Button>
                                    <Button variant="contained" color="secondary" onClick={() => handleDelete(question.id)}>Удалить</Button>
                                </>
                            )}
                        </Grid>
                    </Grid>
                ))}

                <Box sx={{ marginTop: '20px' }}>
                    <Typography variant="h6">Добавить новый вопрос</Typography>
                    <TextField
                        label="Номер урока"
                        value={newQuestion.lessonNumber}
                        onChange={(e) => setNewQuestion({ ...newQuestion, lessonNumber: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Текст вопроса"
                        value={newQuestion.questionText}
                        onChange={(e) => setNewQuestion({ ...newQuestion, questionText: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Текст ответа"
                        value={newQuestion.answerText}
                        onChange={(e) => setNewQuestion({ ...newQuestion, answerText: e.target.value })}
                        fullWidth
                        margin="normal"
                        multiline // Позволяет вводить несколько строк
                        rows={4}  // Устанавливает количество строк по умолчанию
                        maxRows={8}  // Максимальное количество строк
                    />
                    <Button variant="contained" color="primary" onClick={handleAddQuestion}>Добавить вопрос</Button>
                </Box>
            </Box>
        </Container>
    );
}

export default QuestionsManager;
