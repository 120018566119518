import axios from 'axios';

const BASE_URL = 'http://localhost:8080';

// Функция для получения списка студентов
export const getStudentsInfo = async () => {
    const response = await axios.get(`${BASE_URL}/students-info`, {
        headers: { 'x-application-name': 'web' }
    });
    return response.data;
};

// Функция для отправки оценки ответа
export const evaluateAnswer = async (studentId, questionId, rate) => {
    const response = await axios.post(`${BASE_URL}/progress/evaluate-answer`, {
        studentId,
        questionId,
        rate
    }, {
        headers: { 'x-application-name': 'web' }
    });
    return response.data;
};

// Функция для завершения урока
export const completeLesson = async (studentId) => {
    const response = await axios.post(`${BASE_URL}/progress/complete-lesson/${studentId}`, null, {
        headers: { 'x-application-name': 'web' }
    });
    return response.data;
};

// Функция для начала урока
export const startLesson = async (studentId) => {
    const response = await axios.get(`${BASE_URL}/progress/start-lesson/${studentId}`, null, {
        headers: { 'x-application-name': 'web' }
    });
    return response.data;
};
