import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Импорт для перенаправления
import { jwtDecode } from 'jwt-decode';
import { Container, Typography, Grid, Card, CardContent, LinearProgress, List, ListItem, Link, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, Button, Avatar, CircularProgress } from '@mui/material';import styles from '../styles/StudentDashboard.module.css';
import { getStudentDashboardData } from '../api/progressApi';
import sideMenuBg from '../assets/img/side_menu_bg.png';


const defaultDashboardData = {
    completedLessons: 0,
    completedAssignments: 0,
    learnedQuestions: 0,
    averageLessonDuration: '[недостаточно данных]',
    estimatedCompletionDate: '[недостаточно данных]',
    worstQuestionsToRepeat: ['Нет данных для повторения'],
    questionsForNextLesson: ['Нет данных для следующего занятия'],
    name: 'Student Name' // Дефолтное значение для имени студента
};

const StudentDashboard = () => {
    const [dashboardData, setDashboardData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeSection, setActiveSection] = useState('educationProgress');
    const navigate = useNavigate();
    const hasFetchedData = useRef(false); // Используем useRef для предотвращения повторных вызовов

    useEffect(() => {
        if (hasFetchedData.current) return;
        hasFetchedData.current = true;

        const token = localStorage.getItem('authToken');

        if (!token) {
            navigate('/login');
        } else {
            // Извлекаем studentId из JWT токена
            const studentId = jwtDecode(token).sub;
            console.log('studentId:', studentId);
            const fetchData = async () => {
                try {
                    const { data, error } = await getStudentDashboardData(studentId); // Передаем извлеченный studentId
                    if (error) {
                        // Если получена ошибка 401 или 403, перенаправляем на страницу логина
                        if (error === 401 || error === 403 || error === 400) {
                            localStorage.removeItem('authToken');
                            navigate('/login');
                        }
                    } else {
                        setDashboardData(data || defaultDashboardData);
                    }
                } catch (error) {
                    console.error('Ошибка при получении данных:', error);
                    setDashboardData(defaultDashboardData);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [navigate]);

    // Добавляем функцию для переключения секций
    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    if (loading) {
        return <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
            <CircularProgress /> {/* Лоадер для вопросов */}
        </Grid>;
    }

    if (!dashboardData) {
        return <p>Нет данных для отображения.</p>;
    }

    return (
        <Container disableGutters
                   maxWidth={false}
                   className={styles.container}>
            <Grid container spacing={3}>
                {/* Боковое меню */}
                <Grid item xs={12} md={3}>
                    <Card className={styles.menuCard} sx={{ background: 'linear-gradient(to right, white 95%, #E2E4E5 5%)' }}>
                        <CardContent>
                            <Avatar
                                alt="Student Avatar"
                                src="/static/images/avatar/1.jpg"
                                className={styles.avatar}
                                sx={{ width: 80, height: 80 }}
                            />
                            <Typography variant="h5" gutterBottom className={styles.menuText}>
                                {dashboardData.name}
                            </Typography>
                        </CardContent>
                        <List>
                            <ListItem
                                button
                                onClick={() => handleSectionChange('educationProgress')}
                                className={styles.menuItem}>
                                <Typography variant="body1" className={styles.menuText}>
                                    Прогресс обучения
                                </Typography>
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => handleSectionChange('theory')}
                                className={styles.menuItem}>
                                <Typography variant="body1" className={styles.menuText}>
                                    Теория
                                </Typography>
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => handleSectionChange('practicProgress')}
                                className={styles.menuItem}>
                                <Typography variant="body1" className={styles.menuText}>
                                    Практические задания
                                </Typography>
                            </ListItem>

                            <ListItem
                                button
                                onClick={() => handleSectionChange('employmentProgress')}
                                className={styles.menuItem}>
                                <Typography variant="body1" className={styles.menuText}>
                                    Трудоустройство
                                </Typography>
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => handleSectionChange('resources')}
                                className={styles.menuItem}>
                                <Typography variant="body1" className={styles.menuText}>
                                    Полезные ссылки
                                </Typography>
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => handleSectionChange('logout')}
                                className={styles.menuItem}>
                                <Typography variant="body1" className={styles.menuText}>
                                    Выход
                                </Typography>
                            </ListItem>
                        </List>
                    </Card>
                </Grid>

                {/* Правая колонка с контентом */}
                <Grid item xs={12} md={9}>  {/* Установлено md={10} для 80% ширины */}
                    {activeSection === 'educationProgress' && (
                        <>
                            <Typography variant="h4" gutterBottom>
                                Прогресс обучения
                            </Typography>

                            {/* Карточки с прогрессом обучения */}
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card sx={{ height: '100px' }}>
                                        <CardContent>
                                            <Typography variant="h6">Пройденные занятия</Typography>
                                            <Typography variant="body1">{dashboardData.completedLessons} из 27</Typography>
                                            <LinearProgress variant="determinate" value={(dashboardData.completedLessons / 27) * 100} />
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card sx={{ height: '100px' }}>
                                        <CardContent>
                                            <Typography variant="h6">Завершенные практические задания</Typography>
                                            <Typography variant="body1">{dashboardData.completedAssignments} из 10</Typography>
                                            <LinearProgress variant="determinate" value={(dashboardData.completedAssignments / 10) * 100} />
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card sx={{ height: '100px' }}>
                                        <CardContent>
                                            <Typography variant="h6">Пройденные вопросы</Typography>
                                            <Typography variant="body1">{dashboardData.learnedQuestions} из 170</Typography>
                                            <LinearProgress variant="determinate" value={(dashboardData.learnedQuestions / 170) * 100} />
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card sx={{ height: '100px' }}>
                                        <CardContent>
                                            <Typography variant="h6">Общий прогресс обучения</Typography>
                                            {/* Процент завершенности */}
                                            <Typography variant="body2" color="textSecondary">
                                                {`${(((dashboardData.learnedQuestions + dashboardData.completedAssignments) / 180) * 100).toFixed(2)}% завершено`}
                                            </Typography>
                                            <LinearProgress
                                                variant="determinate"
                                                value={((dashboardData.learnedQuestions + dashboardData.completedAssignments) / 180) * 100}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card sx={{ height: '100px' }}>
                                        <CardContent>
                                            <Typography variant="h6">Средняя длительность урока</Typography>
                                            <Typography variant="body3">{dashboardData.averageLessonDuration || '[недостаточно данных]'}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card sx={{ height: '100px' }}>
                                        <CardContent>
                                            <Typography variant="h6">Примерная дата завершения обучения</Typography>
                                            <Typography variant="body1">{dashboardData.estimatedCompletionDate || '[недостаточно данных]'}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>


                            {/* Список топ-10 худших вопросов и вопросы на будущее занятие */}
                            <Grid container spacing={3} sx={{ marginTop: '20px' }}>
                                <Grid item xs={12} sm={6}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6">Топ 5 худших вопросов для повторения</Typography>
                                            <List>
                                                {dashboardData.worstQuestionsToRepeat.length > 0 ? (
                                                    dashboardData.worstQuestionsToRepeat.map((question, index) => (
                                                        <ListItem key={index} sx={{ fontSize: '13px' }}>
                                                            {index + 1}. {question.questionText} {/* Предполагается, что это объекты с полем questionText */}
                                                        </ListItem>
                                                    ))
                                                ) : (
                                                    <Typography variant="body2" color="textSecondary">
                                                        [Информация появится после завершения первого урока]
                                                    </Typography>
                                                )}
                                            </List>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6">Вопросы к следующему занятию</Typography>
                                            <List>
                                                {dashboardData.questionsForNextLesson.map((question, index) => (
                                                    <ListItem key={index} sx={{ fontSize: '13px' }}>
                                                        {index + 1}. {question.questionText} {/* Предполагается, что это объекты с полем questionText */}
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {activeSection === 'employmentProgress' && (
                        <>
                            <Typography variant="h4" gutterBottom>
                                Прогресс трудоустройства
                            </Typography>

                            {/* Карточки с прогрессом трудоустройства */}
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6">Технические интервью</Typography>
                                            <Typography variant="body1">0 из 10</Typography>
                                            <LinearProgress variant="determinate" value={(0 / 10) * 100} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>

                            {/* Таблица с откликами на вакансии */}
                            <Grid container spacing={3} sx={{ marginTop: '20px' }}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>Список откликов на вакансии</Typography>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Компания</TableCell>
                                                    <TableCell>Дата отклика</TableCell>
                                                    <TableCell>Статус</TableCell>
                                                    <TableCell>Примечания</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Компания А</TableCell>
                                                    <TableCell>10/10/2024</TableCell>
                                                    <TableCell>В ожидании</TableCell>
                                                    <TableCell>—</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {activeSection === 'practicProgress' && (
                        <>
                            <Typography variant="h4" gutterBottom>
                                Практика
                            </Typography>

                            {/* Карточки с прогрессом трудоустройства */}
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6">Прогресс выполненных заданий</Typography>
                                            <Typography variant="body1">0 из 10</Typography>
                                            <LinearProgress variant="determinate" value={(0 / 10) * 100} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>

                            {/* Таблица с откликами на вакансии */}
                            <Grid container spacing={3} sx={{ marginTop: '20px' }}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>Список практических заданий</Typography>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Название</TableCell>
                                                    <TableCell>Тема</TableCell>
                                                    <TableCell>Статус</TableCell>
                                                    <TableCell>Заметки преподователя</TableCell>
                                                    <TableCell>Действия</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Тестовое задание 1</TableCell>
                                                    <TableCell>API</TableCell>
                                                    <TableCell>В процессе</TableCell>
                                                    <TableCell>Доделать переменные окружения</TableCell>
                                                    <TableCell><Button
                                                        type="submit"
                                                        variant="contained">
                                                        Скачать задание
                                                    </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {activeSection === 'resources' && (
                        <>
                            <Typography variant="h4" gutterBottom>
                                Полезные ссылки
                            </Typography>

                            {/* Таблица с полезными ресурсами */}
                            <Grid container spacing={3} sx={{ marginTop: '20px' }}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6">Полезные ресурсы</Typography>
                                            <List>
                                                <ListItem>
                                                    <Link href="https://example.com" target="_blank">Полезный ресурс 1</Link>
                                                </ListItem>
                                                <ListItem>
                                                    <Link href="https://example.com" target="_blank">Полезный ресурс 2</Link>
                                                </ListItem>
                                            </List>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

export default StudentDashboard;
