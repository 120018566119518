// src/api/authApi.js
export const login = async (login, password) => {
    const requestData = {
        login: login.replace(/\D/g, ''), // Очищаем телефон от символов, если это телефон
        password: password,
    };

    try {
        const response = await fetch('http://localhost:8080/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-application-name': 'web',
            },
            body: JSON.stringify(requestData),
        });

        const data = await response.json();
        if (response.ok) {
            return data; // Возвращаем успешный результат
        } else {
            throw new Error(data.error || 'Ошибка авторизации');
        }
    } catch (error) {
        throw new Error('Ошибка подключения к серверу');
    }
};
