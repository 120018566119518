// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: "'Inter', sans-serif",
        h5: {
            fontSize: '16px', // Размер шрифта для h5
        },
        h6: {
            fontSize: '14px', // Размер шрифта для h6
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#007bff',
                    color: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    '&:hover': {
                        backgroundColor: '#0056b3',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginBottom: '15px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h4: {
                    fontSize: '2rem',
                    marginBottom: '20px',
                },
                body2: {
                    fontSize: '1rem',
                    color: '#555',
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    maxWidth: 'none', // Отключаем ограничение ширины
                    padding: '24px',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: '16px', // Устанавливаем размер шрифта для основного текста в ListItem
                },
            },
        },  MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: '16px', // Устанавливаем размер шрифта для основного текста в ListItem
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    marginTop: '16px', // Добавляем отступ сверху
                },
            },
        },
    },
});

export default theme;
