import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import sImage from '../assets/img/s.webp'; // Убедитесь, что путь к изображению правильный

const WelcomePage = () => {
    const navigate = useNavigate();

    return (
        <Container
            maxWidth={false}
            sx={{
                minHeight: '90vh', // Используем minHeight для предотвращения обрезания содержимого
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' }, // На маленьких экранах столбиком, на больших — строкой
                alignItems: 'center',
                justifyContent: 'space-between',
                overflowX: 'hidden',
                padding: 0,
            }}
        >
            {/* Левый блок */}
            <Box
                sx={{
                    flex: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: { xs: 'center', md: 'flex-start' },
                    padding: '20px',
                    maxWidth: { xs: '100%', md: '60%' },
                    textAlign: { xs: 'center', md: 'left' },
                }}
            >
                <Typography
                    variant="h3"
                    gutterBottom
                    sx={{
                        fontSize: { xs: '1.8rem', md: '2.5rem' },
                        fontWeight: 700,
                        color: '#333',
                        textAlign: { xs: 'center', md: 'left' }, // Заголовок остается адаптивным
                    }}
                >
                    Добро пожаловать на платформу обучения тестированию ПО!
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: { xs: '1rem', md: '1.2rem' },
                        lineHeight: '1.8',
                        color: '#555',
                        textAlign: 'left', // Всегда выравниваем текст по левому краю
                        marginBottom: '20px',
                    }}
                >
                    Здесь вы получите необходимые теоретические и практические навыки, которые помогут устроиться на первую работу тестировщиком ПО уровня Middle. <br />
                    Формат обучение - персональные занятия 2-3 раза в неделю. Преподаватель выдает материал урока затем на следующий день происходит онлайн-созвон для его проверки, затем выдается следующий материал. Среднее время обучения 3 месяца. <br />
                    Обучающая платформа является закрытой и зарегистрироваться можно только имея <span style={{ color: 'red' }}>пригласительный ключ</span>. Узнать подробные условия обучения и получить ключ можно связавшись с администратором через Telegram:
                    <a
                        href="https://t.me/Max_Alexeenko"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            color: '#007bff',
                            textDecoration: 'none',
                        }}
                    >
                        {' '}
                        @Max_Alexeenko
                    </a>.
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: '10px',
                        marginTop: '20px',
                        width: '100%',
                        justifyContent: { xs: 'center', md: 'flex-start' },
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            padding: '10px 20px',
                            fontSize: '16px',
                            textTransform: 'none',
                            width: { xs: '100%', md: 'auto' },
                        }}
                        onClick={() => navigate('/register')}
                    >
                        Регистрация
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                            padding: '10px 20px',
                            fontSize: '16px',
                            textTransform: 'none',
                            width: { xs: '100%', md: 'auto' },
                        }}
                        onClick={() => navigate('/login')}
                    >
                        Вход
                    </Button>
                </Box>
            </Box>

            {/* Правый блок */}
            <Box
                sx={{
                    flex: 1.5,
                    display: { xs: 'none', md: 'flex' }, // Скрываем изображение на устройствах с шириной экрана < 1024px
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <img
                    src={sImage}
                    alt="Обучение тестированию"
                    style={{
                        maxWidth: '90%',
                        height: 'auto',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Легкая тень
                    }}
                />
            </Box>
        </Container>
    );
};

export default WelcomePage;
