import axios from 'axios';

// src/api/progressApi.js
export const getStudentDashboardData = async (studentId) => {
    const token = localStorage.getItem('authToken'); // Получаем токен из localStorage
    console.log('Токен:', token); // Логируем токен для проверки
    try {
        const response = await fetch(`http://localhost:8080/progress/dashboard/${studentId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Передаем токен в заголовке Authorization
                'x-application-name': 'web',
                'Content-Type': 'application/json'
            },
        });

        if (response.ok) {
            const data = await response.json();
            return { data };
        } else if (response.status === 401 || response.status === 403 || response.status === 400) {
            return { error: response.status }; // Возвращаем код ошибки
        } else {
            throw new Error('Ошибка получения данных');
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
};
