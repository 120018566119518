import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, List, ListItem, Avatar, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, Button, CircularProgress, TextField, Box } from '@mui/material';
import { getStudentsInfo, evaluateAnswer, completeLesson } from '../api/teacherPanelApi'; // Импорт API функций
import styles from '../styles/StudentDashboard.module.css';

const defaultTeacherPanelPageData = {
    name: 'Максим' // Дефолтное значение для имени учителя
};

const TeacherPanelPage = () => {
    const [dashboardData] = useState(defaultTeacherPanelPageData);
    const [studentsData, setStudentsData] = useState([]); // Состояние для списка учеников
    const [activeSection, setActiveSection] = useState('students');
    const [activeLesson, setActiveLesson] = useState(null); // ID текущего урока
    const [timer, setTimer] = useState(0); // Время урока в секундах
    const [lessonStarted, setLessonStarted] = useState(false); // Флаг, что урок начат
    const [gradedQuestions, setGradedQuestions] = useState({}); // Оцененные вопросы
    const [currentQuestions, setCurrentQuestions] = useState([]); // Вопросы для текущего урока
    const [studentsLoading, setStudentsLoading] = useState(true); // Состояние загрузки для списка учеников
    const [questionsLoading, setQuestionsLoading] = useState(true); // Состояние загрузки для базы вопросов
    const [error, setError] = useState(null); // Состояние ошибки
    const [questions, setQuestions] = useState([]);
    const [newQuestion, setNewQuestion] = useState({ lessonNumber: '', questionText: '', answerText: '' });
    const [editingId, setEditingId] = useState(null);
    const [editedQuestion, setEditedQuestion] = useState({ lessonNumber: '', questionText: '', answerText: '' });

    // Загружаем список учеников
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getStudentsInfo(); // Запрос списка учеников
                setStudentsData(data); // Установка полученных данных
                setStudentsLoading(false); // Устанавливаем, что данные загружены
            } catch (error) {
                setError(error.message);
                setStudentsLoading(false); // В случае ошибки завершение загрузки
            }
        };

        fetchData();
    }, []);

    // Загружаем вопросы с API при загрузке компонента
    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await fetch('http://localhost:8080/questions', {
                    headers: { 'x-application-name': 'web' },
                });
                const data = await response.json();
                setQuestions(data);
                setQuestionsLoading(false); // Устанавливаем, что вопросы загружены
            } catch (error) {
                console.error('Ошибка при получении вопросов:', error);
                setQuestionsLoading(false); // В случае ошибки завершаем загрузку
            }
        };

        fetchQuestions();
    }, []);

    const handleDelete = async (id) => {
        try {
            await fetch(`http://localhost:8080/questions/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'x-application-name': 'web',
                    'Content-Type': 'application/json',
                },
            });
            setQuestions(questions.filter((question) => question.id !== id));
        } catch (error) {
            console.error('Ошибка при удалении вопроса:', error);
        }
    };

    const handleEdit = (id, lessonNumber, questionText, answerText) => {
        setEditingId(id);
        setEditedQuestion({ lessonNumber, questionText, answerText });
    };

    const handleSave = async (id) => {
        try {
            await fetch(`http://localhost:8080/questions/update/${id}`, {
                method: 'PUT',
                headers: {
                    'x-application-name': 'web',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedQuestion),
            });
            setQuestions(
                questions.map((question) =>
                    question.id === id ? { ...question, ...editedQuestion } : question
                )
            );
            setEditingId(null);
        } catch (error) {
            console.error('Ошибка при обновлении вопроса:', error);
        }
    };

    const handleAddQuestion = async () => {
        try {
            const response = await fetch('http://localhost:8080/questions/add', {
                method: 'POST',
                headers: {
                    'x-application-name': 'web',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newQuestion),
            });
            const addedQuestion = await response.json();
            setQuestions([...questions, addedQuestion]);
            setNewQuestion({ lessonNumber: '', questionText: '', answerText: '' });
        } catch (error) {
            console.error('Ошибка при добавлении вопроса:', error);
        }
    };

    // Начать урок для ученика
    const handleStartLesson = (student) => {
        setActiveLesson(student.studentId); // Устанавливаем активного ученика
        setLessonStarted(true); // Устанавливаем, что урок начался
        setTimer(0); // Сбрасываем таймер
        setGradedQuestions({}); // Сбрасываем оценки при новом уроке

        // Объединяем вопросы на повторение и вопросы для следующего урока
        const lessonQuestions = [
            ...student.worstQuestionsToRepeat,
            ...student.questionsForNextLesson
        ];

        setCurrentQuestions(lessonQuestions); // Устанавливаем вопросы для текущего урока
    };

    // Оценка вопроса
    const handleGradeQuestion = async (questionId, grade, studentId) => {
        try {
            // Отправляем оценку на сервер
            await evaluateAnswer(studentId, questionId, grade);
            setGradedQuestions((prev) => ({ ...prev, [questionId]: grade }));
            console.log(`Оценка для вопроса ${questionId}: ${grade}`);
        } catch (error) {
            console.error('Ошибка при отправке оценки:', error);
        }
    };

    // Завершение урока
    const handleEndLesson = async () => {
        try {
            // Отправляем запрос на завершение урока
            await completeLesson(activeLesson);
            console.log(`Урок для ученика ${activeLesson} завершен.`);
            setActiveLesson(null); // Сброс текущего урока
            setLessonStarted(false); // Устанавливаем, что урок завершен
            setCurrentQuestions([]); // Сбрасываем вопросы после завершения урока
        } catch (error) {
            console.error('Ошибка при завершении урока:', error);
        }
    };

    // Таймер, который обновляет время каждую секунду
    useEffect(() => {
        let timerInterval;
        if (lessonStarted) {
            timerInterval = setInterval(() => {
                setTimer((prevTime) => prevTime + 1);
            }, 1000);
        }
        return () => {
            clearInterval(timerInterval); // Очищаем интервал при завершении урока или демонтировании компонента
        };
    }, [lessonStarted]);

    // Форматирование времени в "минуты:секунды"
    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    // Форматирование даты (если нужно)
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(); // Пример: 10/10/2024
    };

    // Стили для кнопок оценок
    const buttonStyles = (color) => ({
        marginRight: '8px',
        padding: '4px 8px',
        borderRadius: '50%',
        width: '36px',
        height: '36px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: color,
        minWidth: '36px',
        cursor: 'pointer'
    });

    if (studentsLoading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                <CircularProgress /> {/* Лоадер для списка учеников */}
            </Grid>
        );
    }

    if (questionsLoading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                <CircularProgress /> {/* Лоадер для вопросов */}
            </Grid>
        );
    }

    if (error) {
        return <Typography>Ошибка: {error}</Typography>;
    }

    return (
        <Container disableGutters maxWidth={false} className={styles.container}>
            <Grid container spacing={3}>
                {/* Левая колонка с навигацией */}
                <Grid item xs={12} md={2}>
                    <Card sx={{ backgroundColor: 'lightslategray', color: '#fff', height: '100%' }}>
                        <CardContent>

                            <Avatar
                                alt="Teacher Avatar"
                                src="/static/images/avatar/1.jpg"
                                sx={{ width: 80, height: 80, marginTop: 2 }}
                            />
                            <Typography variant="h5" gutterBottom>
                                {dashboardData.name} {/* Имя учителя */}
                            </Typography>
                        </CardContent>
                        <List>
                            <ListItem button onClick={() => setActiveSection('students')}>
                                <Typography variant="body1" color="inherit"><u>Ученики</u></Typography>
                            </ListItem>
                            <ListItem button onClick={() => setActiveSection('questions')}>
                                <Typography variant="body1" color="inherit"><u>База вопросов</u></Typography>
                            </ListItem>
                        </List>
                    </Card>
                </Grid>

                {/* Правая колонка с контентом */}
                <Grid item xs={12} md={10}>
                    {activeSection === 'students' && (
                        <>
                            <Typography variant="h4" gutterBottom>
                                Список учеников
                            </Typography>

                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Имя</TableCell>
                                                    <TableCell>Номер крайнего занятия</TableCell>
                                                    <TableCell>Дата крайнего занятия</TableCell>
                                                    <TableCell>Общий прогресс обучения</TableCell>
                                                    <TableCell>Действия</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {studentsData.map((student) => (
                                                    <React.Fragment key={student.studentId}>
                                                        {(activeLesson === null || activeLesson === student.studentId) && (
                                                            <TableRow>
                                                                <TableCell>{student.name}</TableCell>
                                                                <TableCell>{student.completedLessons}</TableCell>
                                                                <TableCell>{formatDate(student.lastCompletionDate)}</TableCell>
                                                                <TableCell>{`${(student.learnedQuestions / 100 * 100).toFixed(2)}%`}</TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handleStartLesson(student)}
                                                                        sx={{ marginRight: 1, padding: '4px 8px', fontSize: '14px' }}
                                                                        disabled={activeLesson !== null}
                                                                    >
                                                                        Начать урок
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}

                                                        {activeLesson === student.studentId && (
                                                            <TableRow>
                                                                <TableCell colSpan={5}>
                                                                    <Card>
                                                                        <CardContent>
                                                                            <Typography variant="h6">Урок для {student.name}</Typography>
                                                                            <Typography>Время урока: {formatTime(timer)}</Typography>
                                                                            {currentQuestions.map((question, index) => (
                                                                                <div key={question.id} style={{ marginBottom: '10px' }}>
                                                                                    <Typography>
                                                                                        {index + 1}. {question.questionText}
                                                                                    </Typography>
                                                                                    <Typography color="textSecondary">
                                                                                        Ответ: {question.answerText}
                                                                                    </Typography>
                                                                                    <div>
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            sx={buttonStyles('#d32f2f')}
                                                                                            onClick={() => handleGradeQuestion(question.id, 1, student.studentId)}
                                                                                            disabled={gradedQuestions[question.id] !== undefined}
                                                                                        >
                                                                                            1
                                                                                        </Button>
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            sx={buttonStyles('#fbc02d')}
                                                                                            onClick={() => handleGradeQuestion(question.id, 2, student.studentId)}
                                                                                            disabled={gradedQuestions[question.id] !== undefined}
                                                                                        >
                                                                                            2
                                                                                        </Button>
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            sx={buttonStyles('#388e3c')}
                                                                                            onClick={() => handleGradeQuestion(question.id, 3, student.studentId)}
                                                                                            disabled={gradedQuestions[question.id] !== undefined}
                                                                                        >
                                                                                            3
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                            <Button variant="contained" color="primary" onClick={handleEndLesson}>
                                                                                Завершить урок
                                                                            </Button>
                                                                        </CardContent>
                                                                    </Card>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {activeSection === 'questions' && (
                        <>
                            <Typography variant="h4" gutterBottom>
                                База вопросов
                            </Typography>

                            {/* Добавляем рендер вопросов из QuestionsManager */}
                            {questions.map((question, index) => (
                                <Grid container key={question.id} sx={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                                    <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant="h6">{index + 1}</Typography>
                                    </Grid>
                                    <Grid item xs={11}>
                                        {editingId === question.id ? (
                                            <>
                                                <TextField
                                                    label="Номер урока"
                                                    value={editedQuestion.lessonNumber}
                                                    onChange={(e) => setEditedQuestion({ ...editedQuestion, lessonNumber: e.target.value })}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    label="Вопрос"
                                                    value={editedQuestion.questionText}
                                                    onChange={(e) => setEditedQuestion({ ...editedQuestion, questionText: e.target.value })}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    label="Ответ"
                                                    value={editedQuestion.answerText}
                                                    onChange={(e) => setEditedQuestion({ ...editedQuestion, answerText: e.target.value })}
                                                    fullWidth
                                                    margin="normal"
                                                    multiline
                                                    rows={4}
                                                    maxRows={8}
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleSave(question.id)}
                                                    sx={{ marginRight: '10px' }}
                                                >
                                                    Сохранить
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => setEditingId(null)}
                                                >
                                                    Отмена
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Typography variant="h6">Урок {question.lessonNumber}</Typography>
                                                <Typography variant="body1"><strong>Вопрос:</strong> {question.questionText}</Typography>
                                                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}><strong>Ответ:</strong> {question.answerText}</Typography>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleEdit(question.id, question.lessonNumber, question.questionText, question.answerText)}
                                                    sx={{ marginRight: '10px' }}
                                                >
                                                    Редактировать
                                                </Button>
                                                <Button variant="contained" color="secondary" onClick={() => handleDelete(question.id)}>Удалить</Button>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            ))}

                            <Box sx={{ marginTop: '20px' }}>
                                <Typography variant="h6">Добавить новый вопрос</Typography>
                                <TextField
                                    label="Номер урока"
                                    value={newQuestion.lessonNumber}
                                    onChange={(e) => setNewQuestion({ ...newQuestion, lessonNumber: e.target.value })}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Текст вопроса"
                                    value={newQuestion.questionText}
                                    onChange={(e) => setNewQuestion({ ...newQuestion, questionText: e.target.value })}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Текст ответа"
                                    value={newQuestion.answerText}
                                    onChange={(e) => setNewQuestion({ ...newQuestion, answerText: e.target.value })}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={4}
                                    maxRows={8}
                                />
                                <Button variant="contained" color="primary" onClick={handleAddQuestion}>Добавить вопрос</Button>
                            </Box>
                        </>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

export default TeacherPanelPage;
